<template>
  <div class="wpo-blog-sidebar">
    <div class="widget recent-post-widget" v-if="recentBlog_data.length !== 0">
      <h3>Recent posts</h3>
      <div class="posts">
        <div class="post" v-for="blog in recentBlog_data" :key="blog.id">
          <template v-if="blog.status === 'published'">
            <div class="img-holder">
              <img :src="blog.image" @error="setAltImg" alt>
            </div>
            <div class="details">
              <h4>
                <router-link :to="{ name: 'article', params: params({url: blog.slug}) }">{{blog.title}}</router-link>
              </h4>
              <span class="date">{{blog.date | moment("MMMM DD, YYYY")}}</span>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="widget recent-post-widget" v-if="recentEvents_data.length !== 0">
      <h3>Recent Events</h3>
      <div class="posts">
        <div class="post" v-for="blog in recentEvents_data" :key="blog.id">
          <template v-if="blog.status === 'published'">
            <div class="img-holder">
              <img :src="blog.image" @error="setAltImg" alt>
            </div>
            <div class="details">
              <h4><a :href="blog.link_url" target="_blank">{{blog.title}}</a></h4>
              <span class="date">{{blog.modified_on | moment("MMMM DD, YYYY")}}</span>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import api from "../services/api";
    export default {
        name: "blog-left-content",
        data() {
            return {
                recentBlog_data: [],
                recentEvents_data: []
            }
        },
        methods: {
            params(newParams) {
                return {
                    ...this.$route.params,
                    ...newParams
                }
            },
            setAltImg(event) {
                event.target.src = require("@/assets/images/default-image.jpg")
            },
        },
        async mounted() {
            this.recentBlog_data = await api.recentBlog(3, 'id,status,title,date,image,slug');
            this.recentBlog_data = this.recentBlog_data.data;

            this.recentEvents_data = await api.recentEvents();
            this.recentEvents_data = this.recentEvents_data.data;

            for (const value of this.recentEvents_data) {
                if (value.image !== null && typeof value.image === "number") {
                    let img = await api.getFiles(value.image);
                    if (img !== null) {
                        value.image = img.data['data']['full_url'];
                    }
                }
            }
            for (const value of this.recentBlog_data) {
                if (value.image !== null && typeof value.image === "number") {
                    let img = await api.getFiles(value.image);
                    if (img !== null) {
                        value.image = img.data['data']['full_url'];
                    }
                }
            }
        },
    }
</script>
